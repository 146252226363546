import { crosslettFetch, json } from '../crosslettFetch';
import { ParsedLobbyResponse, parseLobbyResponse } from './parseLobbyResponse';

type CreateLobbyResponse = {
	inviteId: string;
};

export const lobbyApi = {
	joinLobby: (lobbyInviteId: string, screenName: string) => {
		return crosslettFetch(
			`/lobby/join?id=${lobbyInviteId}&screenName=${screenName}`
		)
			.then(json)
			.then(parseLobbyResponse);
	},
	createLobby: (): Promise<CreateLobbyResponse | undefined> => {
		return crosslettFetch(`/lobby`, {
			method: 'PUT',
		}).then(json);
	},
	getLobby: async (lobbyId: string): Promise<ParsedLobbyResponse> => {
		return crosslettFetch(`/lobby?id=${lobbyId}`)
			.then(json)
			.then(parseLobbyResponse);
	},
	startGame: async (lobbyId: string): Promise<ParsedLobbyResponse> => {
		return crosslettFetch(`/lobby/start?id=${lobbyId}`, { method: 'POST' })
			.then(json)
			.then(parseLobbyResponse);
	},
};
