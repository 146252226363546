import { createGlobalStyle, css } from 'styled-components';

const DefaultFontFamily = css`
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
`;

export const GS = createGlobalStyle`
  html {
    /* Set the default 1rem font size to 12px */
    height: 100%;
    font-size: ${(12 * 100) / 16}%;
  }
  html, body {
    background: ${props => props.theme.background.getHexA()};
    &,
    * {
      -webkit-print-color-adjust: exact;
      box-sizing: border-box;
    }
    min-height: 100%;
  }
  body {
    color: ${props => props.theme.text.getHexA()};
    margin: 0;
    ${DefaultFontFamily}
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;


    #root {
      min-height: 100%;
    }

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }

    /* Just a little tighter on the margin-bottoms. I prefer headings to be
    slightly weighted towards the content underneath them */
    h2 {
      margin-bottom: 0.8rem;
    }
    h3 {
      margin-bottom: 0.75rem;
    }
    h2, h3 {
      & + * {
        margin-top: 0;
      }
    }

    // Buttons tend to be stubborn at picking up font fam if you aren't specific.
    button, input, input::placeholder {
      ${DefaultFontFamily}
    }
    button {
        cursor: pointer;
    }
  }
`;
