import { nanoid } from 'nanoid';
import { getType } from 'typesafe-actions';
import { saveGameState } from '../../service/save';
import withLogging from '../../util/actionLogging';
import { gameActions, GameActions } from './gameActions';
import { DieState, GameDice, GameState } from './gameState';

export const createDieState = (
	id: string,
	letter: string,
	stateOverride?: Partial<DieState>
): DieState => ({
	id,
	letter,
	placed: false,
	incorrect: false,
	correct: false,
	x: undefined,
	y: undefined,
	trayIndex: 0,
	...stateOverride,
});

export const withLocalSaveState = (
	reducer: React.Reducer<GameState, GameActions>
) => {
	return (state: GameState, action: GameActions) => {
		const returnState = reducer(state, action);
		saveGameState(returnState);
		return returnState;
	};
};

export const gameStateReducer = withLogging(
	withLocalSaveState((state: GameState, action: GameActions): GameState => {
		switch (action.type) {
			case getType(gameActions.resetBoard): {
				return {
					...state,
					dice: Object.entries(state.dice).reduce((diceState, [id, dice]) => {
						// Reset all the dice to undefined board positions
						diceState[id] = {
							...dice,
							x: undefined,
							y: undefined,
							incorrect: false,
							correct: false,
						};
						return diceState;
					}, {} as GameState['dice']),
				};
			}
			case getType(gameActions.receiveDice): {
				return {
					...state,
					isCorrect: false,
					dice: action.payload.dice.reduce((acc, letter, index) => {
						if (typeof letter === 'string') {
							// Backwards compatibility for when I was sending strings
							const id = nanoid(6);
							acc[id] = createDieState(id, letter, { trayIndex: index });
						} else {
							// Forwards compatibility for when I want to start
							// sending letters with IDs generated at the backend
							const id = letter.diceId;
							acc[id] = createDieState(id, letter.letter, {
								trayIndex: index,
							});
						}

						return acc;
					}, {} as GameState['dice']),
				};
			}
			case getType(gameActions.placeDiceOnBoard): {
				const { diceId, x, y } = action.payload;
				// Get the dice being moved.
				const thisDice = state.dice[diceId];
				// Iterate through pieces and see if we're going on top of somebody else.
				const [targetId, targetDice] =
					Object.entries(state.dice).find(
						([id, dice]) => dice.x === x && dice.y === y
					) || [];

				// We've dropped onto ourselves. Abort.
				if (diceId === targetId) {
					return state;
				}

				return {
					...state,
					dice: {
						...state.dice,
						[diceId]: {
							...thisDice,
							placed: true,
							incorrect: false,
							correct: false,
							x,
							y,
						},
						// We replace the target dice with the moved dice's
						// stats, either sending it back to tray or swapping on
						// the board
						...(targetId && targetDice
							? {
									[targetId]: {
										...targetDice,
										incorrect: false,
										placed: thisDice.placed,
										x: thisDice.x,
										y: thisDice.y,
									},
							  }
							: undefined),
					},
				};
			}
			case getType(gameActions.moveDiceToTray): {
				return {
					...state,
					dice: {
						...state.dice,
						[action.payload.diceId]: {
							...state.dice[action.payload.diceId],
							placed: false,
							correct: false,
							incorrect: false,
							x: undefined,
							y: undefined,
						},
					},
				};
			}
			case getType(gameActions.receiveValidationCheck): {
				const { isCorrect, words, invalidDice, validDice } = action.payload;
				return {
					...state,
					dice: {
						...state.dice,
						...(invalidDice &&
							invalidDice.reduce((dice, invalidId) => {
								dice[invalidId] = {
									...state.dice[invalidId],
									incorrect: true,
									correct: false,
								};
								return dice;
							}, {} as GameDice)),
						...(validDice &&
							validDice.reduce((dice, invalidId) => {
								dice[invalidId] = {
									...state.dice[invalidId],
									incorrect: false,
									correct: true,
								};
								return dice;
							}, {} as GameDice)),
						...(!validDice &&
							!invalidDice &&
							Object.values(state.dice).reduce((dice, die) => {
								dice[die.id] = { ...die, correct: false, incorrect: false };
								return dice;
							}, {} as GameDice)),
					},
					words,
					isCorrect,
				};
			}
			case getType(gameActions.invalidateBoard): {
				return {
					...state,
					dice: Object.values(state.dice).reduce((dice, die) => {
						dice[die.id] = { ...die, correct: false, incorrect: false };
						return dice;
					}, {} as GameDice),
					isCorrect: false,
				};
			}
			case getType(gameActions.resetLetters): {
				return {
					...state,
					dice: Object.values(state.dice).reduce((acc, die) => {
						acc[die.id] = {
							...die,
							x: undefined,
							y: undefined,
							placed: false,
							incorrect: false,
						};
						return acc;
					}, {} as GameDice),
				};
			}
			default:
				return state;
		}
	})
);
