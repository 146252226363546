import { Buffer } from 'buffer';
import React from 'react';
import { gameActions, GameActions } from '../state/Game/gameActions';
import { DieState, GameDice } from '../state/Game/gameState';
import { CrosslettAPI } from './API/crosslettApi';

export const getNewGame = async (dispatch: React.Dispatch<GameActions>) => {
	dispatch(gameActions.requestRollDice());
	const dice = await CrosslettAPI.getDice();
	dispatch(gameActions.receiveDice(dice));
};

const NUMBER_OF_LETTERS = 12;

export const boardAsString = (dice: GameDice) =>
	JSON.stringify(
		Object.values(dice).map(d => [d.x, d.y, d.correct, d.incorrect].join(', '))
	);

export const trayAsString = (dice: GameDice) =>
	JSON.stringify(
		Object.values(dice).map(d => [d.letter, d.placed, d.trayIndex].join(','))
	);

/**
 * Goes through the dice index and arranges them into a 2D array
 * @param dice
 * @returns
 */
export const getBoardFromDice = (dice: GameDice): Board => {
	return Object.values(dice).reduce((board, die) => {
		if (die.x !== undefined && die.y !== undefined) {
			board[die.y][die.x] = die;
		}
		return board;
	}, getEmptyBoard());
};

const getLastItem = <T>(list: T[]): T => list.slice(-1)[0];

const pivotBoard = (board: Board) =>
	board.map((row, rowIdx) => board.map(r => r[rowIdx]));

/**
 * Goes through a 2D array and gets all consecutive horizontal and vertical
 * sequences of more than one dice
 * @param board
 * @returns
 */
export const getWordsFromBoard = (board: Board) => {
	// Rotate the table for ease of reading downward
	const pivotedBoard = pivotBoard(board);

	// Get horizontal and vertical words
	const words: DieState[][] = [...board, ...pivotedBoard].reduce(
		(wordArray, row) => {
			let insideWord = false;
			row.forEach((die, idx, row) => {
				// If the letter is placed
				if (die) {
					// And the next letter also placed
					if (row[idx + 1]) {
						// We need to push this dice.
						if (!insideWord) {
							// We aren't already in a word so create one
							insideWord = true;
							wordArray.push([die]);
						} else {
							// Else, add this dice to the previously formed word
							getLastItem(wordArray).push(die);
						}
					} else {
						// The next letter isn't a die
						if (insideWord) {
							// But we were inside a word, so this needs pushing
							insideWord = false;
							getLastItem(wordArray).push(die);
						}
					}
				}
			});
			return wordArray;
		},
		[] as DieState[][]
	);

	return words;
};

const checkWordsAreContiguous = (words: DieState[][]) => {
	// Log the connected indexes
	const connectedWordIndexes = new Set<number>();
	const searchedWordIndexes = new Set<number>();

	let inChain = false;
	let currentWordIndex = 0;
	do {
		// Get the current ids in the word we're checking
		const currentWord = words[currentWordIndex];
		const currentWordIds = currentWord.map(die => die.id);

		// Find connected words
		const connectedWords = words
			.map((word, idx) => {
				// Can't be ourselves
				const isNotMe = idx !== currentWordIndex;
				// Some of the other letter ids need to match ours
				const isConnected = word.some(l => currentWordIds.includes(l.id));

				return isNotMe && isConnected ? idx : -1;
			})
			.filter(x => x !== -1);

		// Add the connected words to the set
		connectedWords.forEach(idx => {
			connectedWordIndexes.add(idx);
		});
		// Add the current word to the one searched through
		searchedWordIndexes.add(currentWordIndex);

		// We still have steam if from here there are words we haven't searched
		// but are connected.
		const unsearchedButConnectedWords = Array.from(
			connectedWordIndexes.keys()
		).filter(idx => !searchedWordIndexes.has(idx));
		inChain = unsearchedButConnectedWords.length > 0;
		// Assign the next word to check: one of the ones we've found but not
		// searched yet
		currentWordIndex = unsearchedButConnectedWords[0];
	} while (inChain);

	if (connectedWordIndexes.size === words.length) {
		// We managed to find all the words we were given through connected tree
		// searching
		return true;
	}
	// else, return information on the things we didn't manage to get
	else
		return words
			.filter((w, idx) => !connectedWordIndexes.has(idx))
			.map(word => word.map(d => d.letter).join(''));
};

export const validateWords = async (
	dispatch: React.Dispatch<GameActions>,
	dice: GameDice
) => {
	dispatch(gameActions.requestValidationCheck());
	// Create a board
	const boardOfDice = getBoardFromDice(dice);

	const words = getWordsFromBoard(boardOfDice);
	const diceUsed = new Set<string>(words.flat(2).map(d => d.id));
	const contiguityCheck = checkWordsAreContiguous(words);

	if (diceUsed.size !== NUMBER_OF_LETTERS) {
		console.log(
			`Letters not used in words: ${Object.values(dice)
				.filter(dice => !diceUsed.has(dice.id))
				.map(dice => dice.letter)
				.join(',')}`
		);
		dispatch(
			gameActions.receiveValidationCheck({ isCorrect: false, words: {} })
		);
	} else if (contiguityCheck !== true) {
		console.log(`Not all words attached: ${contiguityCheck.join(',')}`);
		dispatch(
			gameActions.receiveValidationCheck({ isCorrect: false, words: {} })
		);
	} else {
		const validCheck = await CrosslettAPI.checkWords(
			words.map(word => word.map(die => die.letter).join(''))
		);

		// Go through and see which words didn't return as valid
		const newDiceSet = words.reduce((diceSet, wordDice) => {
			// join the word into something we can use to access the results
			const word = wordDice.map(d => d.letter).join('');
			// Access the results from the check received by the response
			const wordIsValid = validCheck[word]?.valid;
			if (wordIsValid !== undefined) {
				// For each die in this word, set the validity status. We want "invalid"
				// status to be a one way gate, if it flips to true then we should leave
				// it true
				wordDice.forEach(d => {
					const die = diceSet.get(d.id);
					if (die) {
						// So if the dice is incorrect, let the status stay Else, use the
						// word's validity this ensures any wrong letters will always be red
						die.incorrect = die.incorrect ? die.incorrect : !wordIsValid;
					} else {
						diceSet.set(d.id, { ...d, incorrect: !wordIsValid });
					}
				});
			}
			return diceSet;
		}, new Map<string, DieState>());
		dispatch(
			gameActions.receiveValidationCheck({
				isCorrect: Object.values(validCheck).every(v => v.valid),
				words: validCheck,
				invalidDice: Array.from(newDiceSet.values())
					.filter(d => d.incorrect)
					.map(d => d.id),
				validDice: Array.from(newDiceSet.values())
					.filter(d => !d.incorrect)
					.map(d => d.id),
			})
		);
	}
};

export type Board = (DieState | undefined)[][];

export const getEmptyBoard = (): Board =>
	Array.from(Array(6)).map(_ => Array.from(Array(6)));
