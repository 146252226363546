import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../Button/Button';

const NavLinkUsingButton = (props: NavLinkProps) => {
	return <Button as={NavLink} {...props} />;
};

/**
 * Responds to ${Button} styling. Should it?
 */
export const ButtonStyleNavLink = styled(NavLinkUsingButton)`
	/* Remove default underlining and align horizontally centered */
	text-decoration: none;

	/* Now align vertically and horizontally centered */
	display: flex;
	align-items: center;
	justify-content: center;
`;
