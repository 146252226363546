export enum ActionType {
	JoinLobby = 'JOIN_LOBBY',
	LeaveLobby = 'LEAVE_LOBBY',
	PlayerJoinedLobby = 'PLAYER_JOINED_LOBBY',
	PlayerLeavesLobby = 'PLAYER_LEAVES_LOBBY',
	ReadyUpForNextRound = 'READY_UP_FOR_NEXT_ROUND',
	UnreadyForNextRound = 'UNREADY_FOR_NEXT_ROUND',
	GameStateUpdated = 'GAME_STATE_UPDATED',
	GameAnswerSubmitted = 'GAME_ANSWER_SUBMITTED',
	GameAnswerValidated = 'GAME_ANSWER_VALIDATED',
	NewGameScheduled = 'NEW_GAME_SCHEDULED',
	NewGameRequested = 'NEW_GAME_REQUESTED',
	GameWon = 'GAME_WON',
	GameOver = 'GAME_OVER',
	SubscribeToPlayer = 'SUBSCRIBE_TO_PLAYER',
	UnsubscribeFromPlayer = 'UNSUBSCRIBE_FROM_PLAYER',
}
