import * as React from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';
import { boardAsString, trayAsString } from '../../service/game';
import { gameActions } from '../../state/Game/gameActions';
import { useGameDispatch, useGameState } from '../../state/Game/gameContext';
import { GameDice } from '../../state/Game/gameState';
import { MAX_SPACE_SIZE, MIN_SPACE_SIZE } from '../Board/BoardSpace';
import GridSquare from '../Board/GridSquare';
import { ItemTypes } from '../dropUtils';
import Die from './Die';
import { DiceItem } from '../../service/API/lobby/parseLobbyResponse';

export const TrayWrapper = styled.div`
	align-self: center;
	justify-self: center;
	display: flex;

	margin: 5px;
	align-items: center;

	> div {
		display: grid;
		grid-gap: 5px;
		grid-template-columns: repeat(
			6,
			minmax(${MIN_SPACE_SIZE}, ${MAX_SPACE_SIZE})
		);
	}
`;

interface ITrayProps {
	dice: GameDice;
}

const Tray: React.FunctionComponent<ITrayProps> = React.memo(
	({ dice }) => {
		const dispatch = useGameDispatch();
		const [, drop] = useDrop(() => ({
			accept: ItemTypes.DICE,
			drop: (item: DiceItem) =>
				dispatch(gameActions.moveDiceToTray(item.diceId)),
		}));
		return (
			<TrayWrapper ref={drop}>
				<div>
					{Object.entries(dice).map(([id, die]) => (
						<GridSquare key={id}>
							<Die diceId={id} {...die} />
						</GridSquare>
					))}
				</div>
			</TrayWrapper>
		);
	},
	(prev, next) => {
		return trayAsString(prev.dice) === trayAsString(next.dice);
	}
);

export default Tray;
