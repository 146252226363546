import * as React from 'react';
import styled from 'styled-components';

/**
 * A module rendering the CSS and DOM structure for rendering a "square" inside
 * a responsive wrapper
 */

export interface IGridSquareProps {
	className?: string;
}

const GridSquareWrapper = styled.div`
	// The spacer will take up the square's full area. Children will need to be
	// absolutely positioned against the relative parent.
	position: relative;

	> * {
		width: 100%;
	}

	// This is the spacer
	.background {
		padding-top: 100%;
		width: 100%;
	}

	.childrenContainer {
		// Render on top of the padding spacer
		position: absolute;
		top: 0;

		// By default, center the item in the grid space
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	}
`;

const GridSquare = React.forwardRef<
	any,
	React.PropsWithChildren<IGridSquareProps>
>(({ className, children }, ref) => {
	return (
		<GridSquareWrapper className={className} ref={ref}>
			<div className="background" />
			<div className="childrenContainer">{children}</div>
		</GridSquareWrapper>
	);
});

export default GridSquare;
