import { useEffect, useRef, useState } from 'react';
import { getWebSocket } from '../service/API/crosslettWs';
import { EventBodies } from '@no-q-webapp/websocket/src/service/eventTypes';
import { nanoid } from 'nanoid';

export interface UseWebSocketReturn {
	lastMessage: EventBodies | undefined;
	readyState: number;
	sendMessage: (json: any) => void;
	ws: WebSocket;
}

const webSocketSubscribers = new Set<string>();

/**
 * A hook that allows reading of the websocket state the latest message
 * @returns
 */
export const useWebSocket = (): UseWebSocketReturn => {
	const wsRef = useRef(getWebSocket());
	const [WSReadyState, setReadyState] = useState(wsRef.current.readyState);
	const [WSLatestMessage, setLatestMessage] = useState<EventBodies | undefined>(
		undefined
	);
	const messageQueue = useRef<any[]>([]);
	const addMessageToQueue = (message: any) => {
		if (process.env.NODE_ENV === 'development') {
			console.log(`Queued WS Message: %o`, message);
		}
		messageQueue.current.push(message);
	};
	const sendJSON = (json: any) => {
		if (process.env.NODE_ENV === 'development') {
			console.log(`Outgoing WS Message: %o`, json);
		}
		wsRef.current.send(JSON.stringify(json));
	};

	const sendMessage = (json: any) => {
		if (WSReadyState === WebSocket.OPEN) {
			sendJSON(json);
		} else {
			addMessageToQueue(json);
		}
	};

	useEffect(() => {
		const subId = nanoid();
		webSocketSubscribers.add(subId);
		const onOpen = () => {
			setReadyState(wsRef.current.readyState);
			messageQueue.current.forEach(sendJSON);
			messageQueue.current = [];
		};
		const onMessage = event => {
			setLatestMessage(JSON.parse(event.data));
			if (process.env.NODE_ENV === 'development') {
				console.log(`Incoming WS Message: %o`, event.data);
			}
		};
		const onClose = () => {
			setReadyState(wsRef.current.readyState);
		};
		wsRef.current.addEventListener('open', onOpen);
		wsRef.current.addEventListener('close', onClose);
		wsRef.current.addEventListener('message', onMessage);
		return () => {
			wsRef.current.removeEventListener('open', onOpen);
			wsRef.current.removeEventListener('close', onClose);
			wsRef.current.removeEventListener('message', onMessage);
			webSocketSubscribers.delete(subId);

			// Close the socket if we were the last one.
			if (webSocketSubscribers.size === 0) {
				wsRef.current.close();
			}
		};
	}, []);

	return {
		lastMessage: WSLatestMessage,
		readyState: WSReadyState,
		sendMessage,
		ws: wsRef.current,
	};
};
