import * as React from 'react';
import { setUserId } from '../../service/user';
import Button from '../Button/Button';

interface ITextPanelProps {}

const TextPanel: React.FunctionComponent<ITextPanelProps> = props => {
	const [s, set] = React.useState<string>('');
	const setUID = () => setUserId(s);
	return (
		<div>
			<input value={s} onChange={e => set(e.currentTarget.value)} />
			<Button onClick={setUID}>Set</Button>
		</div>
	);
};

export default TextPanel;
