import * as React from 'react';
import { getNewGame, validateWords } from '../../service/game';
import {
	getSaveState,
	loadSharedGameData,
	SHARED_GAME_QS,
} from '../../service/save';
import { gameActions } from '../../state/Game/gameActions';
import { useGameDispatch, useGameState } from '../../state/Game/gameContext';
import Game from './Game';
import GameStateProvider from '../../state/Game/GameStateProvider';
import FreePlayNav from '../Nav/FreePlayNav';

interface IFreePlayProps {}

const InnerFreePlay: React.FunctionComponent<IFreePlayProps> = props => {
	// FreePlay is wrapped with the GameStateProvider inside the default export.
	const dispatch = useGameDispatch();
	const state = useGameState();

	// Effect to hydrate the game state on load of the FreePlay component
	React.useEffect(() => {
		const [url, search] = location.href.split('?');
		const searchParams = new URLSearchParams(search);
		if (searchParams.has(SHARED_GAME_QS)) {
			const letters = loadSharedGameData(
				searchParams.get(SHARED_GAME_QS) || ''
			);
			dispatch(gameActions.receiveDice(letters));
			location.replace(url);
		}
		if (Object.entries(state.dice).length === 0) {
			getNewGame(dispatch);
		}
	}, []);

	return (
		<>
			<FreePlayNav />
			<Game validate={validateWords} />
		</>
	);
};

export default () => (
	<GameStateProvider initGameStateFromProps={getSaveState()}>
		<InnerFreePlay />
	</GameStateProvider>
);
