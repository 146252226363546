const USER_ID_KEY = 'crosslettUID';
const SESSION_ID_KEY = 'crosslettSID';
import { nanoid } from 'nanoid';

const getSessionId = () => {
	const session = window.localStorage.getItem(SESSION_ID_KEY);
	if (!session) {
		const id = nanoid();
		window.localStorage.setItem(SESSION_ID_KEY, id);

		return id;
	}
	return session;
};

export const setUserId = (id: string) => {
	localStorage.setItem(USER_ID_KEY, id);
};

export const getUserInfo = () => {
	return {
		userId: localStorage.getItem(USER_ID_KEY),
		sessionId: getSessionId(),
	};
};
