import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { lobbyActions } from '../../../state/Lobby/lobbyActions';
import { useLobbyDispatch } from '../../../state/Lobby/lobbyContext';

export const useLobbyIdOnMount = (
	lobbyDispatch: ReturnType<typeof useLobbyDispatch>
) => {
	const loc = useLocation();

	useEffect(() => {
		const qp = new URLSearchParams(loc.search);
		const inviteId = qp.get('lobbyId');
		if (inviteId) {
			lobbyDispatch(lobbyActions.updateLobbyId(inviteId));
		}
	}, [loc.search]);
};
