import { Colour } from '@cj09/css';
import * as React from 'react';
import { useDrag } from 'react-dnd';
import styled, { css } from 'styled-components';
import GridSquare, { IGridSquareProps } from '../Board/GridSquare';
import { ItemTypes } from '../dropUtils';
import { DiceItem } from '../../service/API/lobby/parseLobbyResponse';

const DIE_SIZE = '60px';

const DefaultDieFont = css`
	color: ${props =>
		props.theme.foreground.override({ l: 12, h: 130, s: 55 }).getHexA()};
`;

export const BaseDieStyling = css`
	background: ${props => props.theme.accent.getHexA()};
	border-radius: 5px;
	${DefaultDieFont}
`;
// = (colour: Colour) => colour.override({ l: 12, h: 130, s: 55 }).getHexA());

export const DieWrapper = styled.div<
	{
		$ghostLetter?: boolean;
		$confirmLetter?: boolean;
		$dangerLetter?: boolean;
		children?: React.ReactNode;
	} & IGridSquareProps
>`
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	font-size: 3rem;
	font-weight: bold;

	${BaseDieStyling}

	background: ${props => {
		if (props.$confirmLetter) {
			return props.theme.confirm.getHexA();
		} else if (props.$dangerLetter) {
			return props.theme.danger.getHexA();
		} else {
			return props.theme.accent.getHexA();
		}
	}};
	color: ${props =>
		props.$dangerLetter
			? props.theme.danger.override({ l: 12, s: 55 }).getHexA()
			: DefaultDieFont};

	opacity: ${props => (props.$ghostLetter ? '40%' : undefined)};
`;

interface IDieProps {
	diceId: string;
	confirm?: boolean;
	danger?: boolean;
	letter: string;
	placed?: boolean;
}

const Die: React.FunctionComponent<IDieProps> = ({
	diceId,
	letter,
	placed,
	confirm,
	danger,
}) => {
	const [collected, drag, dragPreview] = useDrag(
		() => ({
			type: ItemTypes.DICE,
			item: { diceId, letter } as DiceItem,
			collect: monitor => ({
				isDragging: monitor.isDragging(),
			}),
		}),
		[diceId]
	);
	return (
		<DieWrapper
			$ghostLetter={collected.isDragging || placed}
			$confirmLetter={confirm || undefined}
			$dangerLetter={danger}
			ref={drag}
		>
			<span>{letter}</span>
		</DieWrapper>
	);
};

export default Die;
