import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import Game from './components/Game/Game';
import GameStateProvider from './state/Game/GameStateProvider';
import FreePlayNav from './components/Nav/FreePlayNav';
import { GunmetalTheme } from './styling/theme';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import FreePlay from './components/Game/FreePlay';
import { getSaveState } from './service/save';
import LobbyHome from './components/Lobby/LobbyIndex';
import { GS } from './GlobalStyle';
import { Helmet } from 'react-helmet';

interface IAppProps {}

const App: React.FunctionComponent<IAppProps> = props => {
	return (
		<ThemeProvider theme={GunmetalTheme}>
			<Helmet>
				<title>Crosslett</title>
			</Helmet>
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
			/>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<FreePlay />}></Route>
					<Route path="/lobby/*" element={<LobbyHome />}></Route>
					{/* <Route path="*" element={<Navigate to="/" />} /> */}
				</Routes>
			</BrowserRouter>
			<GS />
		</ThemeProvider>
	);
};

export default App;
