import { Colour, ColourType } from "@cj09/css";

export interface CJThemeType {
  background: Colour;
  foreground: Colour;
  accent: Colour;
  confirm: Colour;
  danger: Colour;
  text: Colour;
}

export const GunmetalTheme: CJThemeType = {
  background: new Colour({ mode: ColourType.hex, values: "100F17" }),
  foreground: new Colour({ mode: ColourType.hex, values: "699395" }),
  accent: new Colour({ mode: ColourType.hex, values: "cec7ad" }),
  confirm: new Colour({ mode: ColourType.hex, values: "94dca1" }),
  danger: new Colour({ mode: ColourType.hex, values: "f19f9f" }),
  get text() {
    return this.foreground.override({ l: 95, s: 20 });
  },
};
