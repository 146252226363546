import * as React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import styled from 'styled-components';
import { boardAsString, validateWords } from '../../service/game';
import { gameActions } from '../../state/Game/gameActions';
import { useGameDispatch, useGameState } from '../../state/Game/gameContext';
import Board, { BoardWrapper } from '../Board/Board';
import Tray, { TrayWrapper } from '../Dice/Tray';
import { NAV_HEIGHT } from '../Nav/NavWrapper';

const GameWrapper = styled.div`
	display: flex;
	flex-direction: column;

	min-height: 0;
	overflow-y: 0;

	height: calc(100vh - ${NAV_HEIGHT});
	max-width: 500px;
	margin: 0 auto;

	${BoardWrapper} {
		flex-grow: 2;
	}
	${TrayWrapper} {
		flex-grow: 1;
		flex-shrink: 0;
	}
`;

interface IGameProps {
	validate: typeof validateWords;
}

const isTouchDevice = () =>
	'ontouchstart' in window || navigator.maxTouchPoints > 0;

const Game: React.FunctionComponent<IGameProps> = ({ validate }) => {
	const { dice } = useGameState();
	const gameDispatch = useGameDispatch();
	React.useEffect(() => {
		const diceArray = Object.values(dice);
		const allDicePlaced =
			diceArray.length > 0 &&
			diceArray.every(d => d.placed && d.x !== undefined && d.y !== undefined);

		if (allDicePlaced) {
			validate(gameDispatch, dice);
		} else {
			gameDispatch(gameActions.invalidateBoard());
		}
	}, [boardAsString(dice)]);
	return (
		<DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
			<GameWrapper>
				<Board dice={dice} />
				<Tray dice={dice} />
			</GameWrapper>
		</DndProvider>
	);
};

export default Game;
