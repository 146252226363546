import { ActionType, createAction } from 'typesafe-actions';
import { ParsedLobbyResponse } from '../../service/API/lobby/parseLobbyResponse';
import { gameActions } from '../Game/gameActions';

export const lobbyActions = {
	requestLobbyUpdate: createAction('REQUEST_LOBBY_UPDATE')(),
	updateScreenName: createAction('UPDATE_SCREEN_NAME', (name: string) => ({
		name,
	}))(),
	updateLobbyId: createAction('UPDATE_LOBBY_ID', (lobbyId: string) => ({
		lobbyId,
	}))(),
	receiveLobbyState: createAction(
		'RECEIVE_LOBBY_STATE',
		(lobbyState: ParsedLobbyResponse) => lobbyState
	)(),
};
export type LobbyActions = ActionType<typeof lobbyActions & typeof gameActions>;
