import styled, { css } from 'styled-components';
import { useDrop } from 'react-dnd';

export const MAX_SPACE_SIZE = '60px';
export const MIN_SPACE_SIZE = '30px';

const StyledBoardSpace = styled(GridSquare)<{
	$ghostLetter?: boolean;
	$isFilled?: boolean;
}>`
	border: ${props =>
		!props.$isFilled &&
		`
		solid 2px ${props.theme.background.override({ l: 20 }).getHexA()}
	`};

	background: ${props => props.theme.background.getHexA()};

	font-size: 3rem;
	font-weight: bold;
	color: ${props => props.theme.accent.getHexA()};
	> * {
		width: 100%;
		opacity: ${props => (props.$ghostLetter ? '40%' : undefined)};
	}
`;

import * as React from 'react';
import { ItemTypes } from '../dropUtils';
import { useGameDispatch } from '../../state/Game/gameContext';
import { gameActions } from '../../state/Game/gameActions';
import Die, { DieWrapper } from '../Dice/Die';
import GridSquare from './GridSquare';
import { DiceItem } from '../../service/API/lobby/parseLobbyResponse';

interface IBoardSpaceProps {
	x: number;
	y: number;
	children: React.ReactNode;
}

const BoardSpace: React.FunctionComponent<IBoardSpaceProps> = ({
	x,
	y,
	children,
}) => {
	const dispatch = useGameDispatch();
	const [{ isOver, draggedLetter }, drop] = useDrop(
		() => ({
			accept: ItemTypes.DICE,
			drop: (item: DiceItem) =>
				dispatch(gameActions.placeDiceOnBoard(x, y, item.diceId)),
			collect: monitor => ({
				isOver: !!monitor.isOver(),
				draggedLetter: monitor.getItem(),
			}),
		}),
		[x, y]
	);

	const spaceFilled = !!((isOver && draggedLetter) || children);

	return (
		<StyledBoardSpace ref={drop} $isFilled={spaceFilled}>
			{spaceFilled &&
				(isOver ? (
					<Die
						letter={draggedLetter.letter}
						diceId={draggedLetter.diceId}
						placed={true}
					/>
				) : (
					children
				))}
		</StyledBoardSpace>
	);
};

export default BoardSpace;
