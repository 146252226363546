import { GameState, initialGameState } from '../Game/gameState';

export interface Participant {
	screenName: string;
	solvedCurrentGame: false;
}
export interface LobbyState {
	screenName: string;
	lobbyId?: string;
	lobbyState?: string;
	letterset?: {letters: string; _id: string};
	winner?: string;
	latestGameStartTime?: Date;
	latestGameWinTime?: Date;
	updatedAt?: Date;
	participants?: Participant[];
	game: GameState;
}

const SCREEN_NAME_KEY = 'CROSSLETT_SCREEN_NAME';
const getStoredScreenName = () =>
	window.localStorage.getItem(SCREEN_NAME_KEY) || '';
export const setStoredScreenName = (name: string) => {
	window.localStorage.setItem(SCREEN_NAME_KEY, name);
};

export const initialLobbyState: LobbyState = {
	screenName: getStoredScreenName(),
	game: initialGameState,
};
