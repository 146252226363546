import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes, useNavigate } from 'react-router';
import { CrosslettAPI } from '../../service/API/crosslettApi';
import { getUserInfo } from '../../service/user';
import { lobbyActions } from '../../state/Lobby/lobbyActions';
import { useLobbyDispatch } from '../../state/Lobby/lobbyContext';
import LobbyStateProvider from '../../state/Lobby/LobbyStateProvider';
import Button from '../Button/Button';
import { ButtonStyleNavLink } from '../Nav/StyledNavLink';
import JoinLobby from './JoinLobby';
import Lobby from './Lobby';
import LobbyAdminPanel from './LobbyAdminPanel';
import { LobbyInteractions } from './components/LobbyInteractions';

interface ILobbyHomeProps {}

const LobbyHome: React.FunctionComponent<ILobbyHomeProps> = props => {
	const userInfo = getUserInfo();
	const nav = useNavigate();
	const lobbyDispatch = useLobbyDispatch();
	const createLobby = async () => {
		const lobby = await CrosslettAPI.lobby.createLobby();
		if (!lobby) {
			console.error('Lobby creation failed');
			return;
		}
		lobbyDispatch(lobbyActions.updateLobbyId(lobby.inviteId));
		nav(`join`);
	};

	return (
		<>
			<Helmet>
				<title>Crosslett - Lobby</title>
			</Helmet>
			<Routes>
				<Route path="/admin" element={<LobbyAdminPanel />} />
				<Route path="/join" element={<JoinLobby />} />
				<Route
					path="/menu"
					element={
						<LobbyInteractions>
							{userInfo.userId ? (
								<Button onClick={createLobby}>Create Lobby</Button>
							) : (
								<Navigate to="../join" replace={true} />
							)}
							<ButtonStyleNavLink to="../join">Join Lobby</ButtonStyleNavLink>
						</LobbyInteractions>
					}
				/>
				<Route path="/game" element={
						<Lobby />
				} />
				<Route path="*" element={<Navigate to="join" />} />
			</Routes>
		</>
	);
};

export default () => (
	<LobbyStateProvider>
		<LobbyHome />
	</LobbyStateProvider>
);
