import React, { createContext, useContext } from 'react';
import { UseWebSocketReturn } from '../../hooks/useWebSocket';

export const LobbyWebSocketContext = createContext<
	UseWebSocketReturn | undefined
>(undefined);

export const useLobbySocket = () => {
	const ws = useContext(LobbyWebSocketContext);
	if (!ws) {
		throw Error('No Lobby Socket?');
	}
	return ws;
};
