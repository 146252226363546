import * as React from 'react';
import { GameDispatchContext, GameStateContext } from '../Game/gameContext';
import { LobbyDispatchContext, LobbyStateContext } from './lobbyContext';
import { lobbyStateReducer } from './lobbyReducer';
import { LobbyState, initialLobbyState } from './lobbyState';
import { useWebSocket } from '../../hooks/useWebSocket';
import { LobbyWebSocketContext } from './lobbyWebSocketContext';
import { lobbyWebSocketMessageMapper } from './lobbyWebSocketToStateMapper';

interface ILobbyStateProviderProps {
	children: React.ReactNode;
	lobbyStateFromProps?: Partial<LobbyState>;
}

const LobbyStateProvider: React.FC<ILobbyStateProviderProps> = ({
	children,
	lobbyStateFromProps,
}) => {
	const [state, dispatch] = React.useReducer(lobbyStateReducer, {
		...initialLobbyState,
		...lobbyStateFromProps,
	});

	const wsState = useWebSocket();

	React.useEffect(() => {
		if (!wsState.lastMessage) {
			return;
		}

		// Given that we don't need component to trigger/request data, we can
		// set up a central handler for all incoming messages near the state
		// tree. Then, components only need to subscribe to state and submit
		// messages.

		// Simply pass the incoming message over to the mapper, which will then flow
		// into state
		lobbyWebSocketMessageMapper(wsState.lastMessage, dispatch)

	}, [wsState.lastMessage])

	return (
		<LobbyWebSocketContext.Provider value={wsState}>
			<LobbyStateContext.Provider value={state}>
				<LobbyDispatchContext.Provider value={dispatch}>
					<GameDispatchContext.Provider value={dispatch}>
						<GameStateContext.Provider value={state.game}>
							{children}
						</GameStateContext.Provider>
					</GameDispatchContext.Provider>
				</LobbyDispatchContext.Provider>
			</LobbyStateContext.Provider>
		</LobbyWebSocketContext.Provider>
	);
};

export default LobbyStateProvider;
