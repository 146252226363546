import styled from 'styled-components';

export const NAV_HEIGHT = '55px';

export const NavWrapper = styled.div`
	display: flex;
	justify-content: space-around;

	height: ${NAV_HEIGHT};
	border-bottom: solid 2px
		${props => props.theme.background.override({ l: 15 }).getHexA()};
	background: ${props => props.theme.background.getHexA()};
	width: 100%;

	> * {
		margin: 0.4rem 0 0.4rem 0;
	}

	h2 {
		margin-top: 0;
		margin-bottom: 0.2rem;
	}

	span {
		white-space: pre;
		text-overflow: ellipsis;
	}
`;
