import { getUserInfo } from '../user';

const ServiceUrlEnvVar = 'REACT_APP_CROSSLETT_SERVICE_URL';
const serviceUrl = process.env[ServiceUrlEnvVar];

if (!serviceUrl) {
	throw Error(`${ServiceUrlEnvVar} not defined.`);
}

export const SessionIdHeader = 'crosslett-session-id';
export const UserIdHeader = 'crosslett-user-id';

const getDefaultHeaders = () => {
	const uI = getUserInfo();
	return {
		[SessionIdHeader]: uI.sessionId,
		...(uI.userId ? { [UserIdHeader]: uI.userId } : {}),
	};
};

export const crosslettFetch: typeof fetch = (input, init) =>
	fetch(`${serviceUrl}${input}`, {
		...init,
		headers: { ...getDefaultHeaders(), ...init?.headers },
	});
export const json = (r: Response) => r.json();
