import { prototype } from 'events';
import * as React from 'react';
import { toast } from 'react-toastify';
import { getNewGame } from '../../service/game';
import { getShareGameData } from '../../service/save';
import { gameActions } from '../../state/Game/gameActions';
import { useGameDispatch, useGameState } from '../../state/Game/gameContext';
import Button from '../Button/Button';
import { NavWrapper } from './NavWrapper';

interface INavProps {}

const FreePlayNav: React.FunctionComponent<INavProps> = props => {
	const { dice } = useGameState();
	const letters = Object.values(dice).map(d => d.letter);
	const clipboardAvailable = 'clipboard' in navigator;

	const shareGameUrl = React.useCallback(() => {
		const urlDataPacket = getShareGameData(letters);
		const url = `${window.location.href}${urlDataPacket}`;
		navigator.clipboard.writeText(url);
		toast.success(`URL copied to clipboard`);
	}, [JSON.stringify(letters)]);

	const resetLetters = () => dispatch(gameActions.resetLetters());

	const dispatch = useGameDispatch();
	return (
		<NavWrapper>
			<Button onClick={() => getNewGame(dispatch)}>New Game</Button>
			<Button onClick={resetLetters}>Reset Letters</Button>
			{clipboardAvailable && <Button onClick={shareGameUrl}>Share Game</Button>}
		</NavWrapper>
	);
};

export default FreePlayNav;
