import styled from 'styled-components';
import Button from '../../Button/Button';

export const LobbyInteractions = styled.div<{ $col?: boolean }>`
	height: 100vh;

	display: flex;
	flex-direction: ${props => (props.$col ? 'column' : 'row')};

	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	align-content: center;

	gap: 30px;

	padding: 10px;
	> ${Button} {
		padding: 1rem;
	}
`;

export const InteractionRow = styled.div<{ $center?: boolean }>`
	flex-basis: 100%;
	text-align: ${props => (props.$center ? 'center' : undefined)};
`;
