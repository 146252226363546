import { v4 as uuid } from 'uuid';
import { Buffer } from 'buffer';
import { shuffleArray } from '../../util/shuffleArray';
import { crosslettFetch, json } from './crosslettFetch';
import { lobbyApi } from './lobby/lobbyAPI';

export const CrosslettAPI = {
	getDice: async () => {
		const letters: string[] = await crosslettFetch(`/dice/roll`).then(json);
		return shuffleArray(letters);
	},
	getDaily: async () => {
		const letters: string[] = await crosslettFetch(`/game/daily`).then(json);
		return letters;
	},
	checkWords: async (words: string[]) => {
		const wordsb64 = Buffer.from(JSON.stringify(words), 'utf-8').toString(
			'base64'
		);
		const validCheck: { [word: string]: { valid: boolean; word: string } } =
			await crosslettFetch(
				`/game/check?words=${encodeURIComponent(wordsb64)}`
			).then(json);
		return validCheck;
	},
	lobby: lobbyApi,
};
