import { ActionType, createAction } from 'typesafe-actions';
import { DiceItem } from '../../service/API/lobby/parseLobbyResponse';

export const gameActions = {
	resetBoard: createAction('RESET_BOARD')(),
	requestRollDice: createAction('REQUEST_ROLL_DICE')(),
	receiveDice: createAction('RECEIVE_DICE', (dice: string[] | DiceItem[]) => ({
		dice,
	}))(),
	placeDiceOnBoard: createAction(
		'PLACE_DICE',
		(x: number, y: number, diceId: string) => ({ x, y, diceId })
	)(),
	moveDiceToTray: createAction('MOVE_DICE_TO_TRAY', (diceId: string) => ({
		diceId,
	}))(),
	requestValidationCheck: createAction('REQUEST_WORDS_CHECK')(),
	receiveValidationCheck: createAction(
		'RECEIVE_VALIDATION_CHECK',
		(wordCheck: {
			isCorrect: boolean;
			words: { [word: string]: { word: string; valid: boolean } };
			invalidDice?: string[];
			validDice?: string[];
		}) => wordCheck
	)(),
	invalidateBoard: createAction('INVALIDATE_BOARD')(),
	resetLetters: createAction('RESET_LETTERS')(),
};
export type GameActions = ActionType<typeof gameActions>;
