import * as React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import {
	boardAsString,
	getBoardFromDice,
	validateWords,
} from '../../service/game';
import { gameActions } from '../../state/Game/gameActions';
import { useGameDispatch, useGameState } from '../../state/Game/gameContext';
import { GameDice } from '../../state/Game/gameState';
import Die from '../Dice/Die';
import BoardSpace, { MIN_SPACE_SIZE, MAX_SPACE_SIZE } from './BoardSpace';
/**
 * 12 dice? 12 by 12 grid seems ok
 */
const BOARD_SIZE = 6;
const ARRAY_OF_BOARD_SIZE = Array.from(Array(BOARD_SIZE));

export const BoardWrapper = styled.div`
	align-self: center;
	justify-self: center;

	margin: 5px;

	display: flex;
	align-items: center;

	> div {
		display: grid;
		grid-gap: 5px;
		grid-template-columns: repeat(
			${BOARD_SIZE},
			minmax(${MIN_SPACE_SIZE}, ${MAX_SPACE_SIZE})
		);
	}
`;

interface IBoardProps {
	className?: string;
	dice: GameDice;
}

const Board: React.FunctionComponent<IBoardProps> = React.memo(
	({ dice, className }) => {
		// Create a board state on the fly, pivoting the word indexing into a 2D
		// array.
		const boardState = React.useMemo(
			() => getBoardFromDice(dice),
			[boardAsString(dice)]
		);

		return (
			<BoardWrapper className={className}>
				<div id="boardGrid">
					{boardState.map((row, rowIdx) =>
						row.map((die, spaceIdx) => (
							<BoardSpace x={spaceIdx} y={rowIdx} key={`${spaceIdx},${rowIdx}`}>
								{die && (
									<Die
										diceId={die.id}
										letter={die.letter}
										confirm={die.correct}
										danger={die.incorrect}
									/>
								)}
							</BoardSpace>
						))
					)}
				</div>
			</BoardWrapper>
		);
	},
	(prev, next) => {
		return boardAsString(prev.dice) === boardAsString(next.dice);
	}
);

export default Board;
