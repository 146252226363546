import { GameState, initialGameState } from '../state/Game/gameState';
import { Buffer } from 'buffer';

const GAME_SAVE_LOCAL_STORAGE_KEY = 'CROSSLETT_GAME_STATE';
const STATE_VERSION_KEY = 'CROSSLETT_GAME_STATE_VERSION';
const CURRENT_SAVE_STATE_VERSION = '2';

export const SHARED_GAME_QS = 'letters';

export const getShareGameData = (letters: string[]) => {
	const buf = Buffer.from(JSON.stringify(letters), 'utf8');
	return `?${SHARED_GAME_QS}=${buf.toString('base64')}`;
};

export const loadSharedGameData = (data: string): string[] => {
	const buf = Buffer.from(data, 'base64');
	return JSON.parse(buf.toString('utf-8'));
};

const encodeStateToString = (game: GameState): string => {
	const buf = Buffer.from(JSON.stringify(game), 'utf-8');
	return buf.toString('base64');
};

export const saveGameState = (game: GameState) => {
	localStorage.setItem(STATE_VERSION_KEY, CURRENT_SAVE_STATE_VERSION);
	localStorage.setItem(GAME_SAVE_LOCAL_STORAGE_KEY, encodeStateToString(game));
};

const decodeStringToState = (
	gameString: string | null,
	stateVersion: string | null
): GameState => {
	const noLocalStorage = !(gameString && stateVersion);
	const notCurrentVersion = stateVersion !== CURRENT_SAVE_STATE_VERSION;
	if (noLocalStorage || notCurrentVersion) {
		console.log(
			`Returning empty game state. Reason: ${
				noLocalStorage
					? `no locally saved game`
					: notCurrentVersion
					? `game version outdated: ${stateVersion} vs. ${CURRENT_SAVE_STATE_VERSION}`
					: `unknown`
			}`
		);
		return initialGameState;
	}
	const buf = Buffer.from(gameString, 'base64');
	return JSON.parse(buf.toString('utf-8'));
};

export const getSaveState = (): GameState => {
	const gameString = localStorage.getItem(GAME_SAVE_LOCAL_STORAGE_KEY);
	const stateVersion = localStorage.getItem(STATE_VERSION_KEY);
	return decodeStringToState(gameString, stateVersion);
};
