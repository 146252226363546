import React, { createContext, useContext } from 'react';
import { LobbyState } from './lobbyState';

export const LobbyStateContext = createContext<LobbyState | undefined>(
	undefined
);
export const LobbyDispatchContext = createContext<
	React.Dispatch<any> | undefined
>(undefined);

export const useLobbyState = () => {
	const gs = useContext(LobbyStateContext);
	if (!gs) {
		throw Error('No Lobby State?');
	}
	return gs;
};

export const useLobbyDispatch = () => {
	const gs = useContext(LobbyDispatchContext);
	if (!gs) {
		throw Error('No Lobby Dispatch?');
	}
	return gs;
};
