import { EventBodies } from '@no-q-webapp/websocket/src/service/eventTypes';
import withLogging from '../../util/actionLogging';
import { LobbyActions, lobbyActions } from './lobbyActions';
import { ActionType } from '@no-q-webapp/websocket/src/service/actionTypes';

export const lobbyWebSocketMessageMapper = (
	message: EventBodies,
	dispatch: React.Dispatch<LobbyActions>
) => {
	switch (message.action) {
		case ActionType.GameStateUpdated: {
			return dispatch(lobbyActions.receiveLobbyState(message.data));
		}
	}
};
