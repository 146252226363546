import * as React from 'react';
import { GameDispatchContext, GameStateContext } from './gameContext';
import { gameStateReducer } from './gameReducer';
import { GameState, initialGameState } from './gameState';

interface IGameStateProviderProps {
	children: React.ReactNode;
	initGameStateFromProps?: GameState;
}

const GameStateProvider: React.FC<IGameStateProviderProps> = ({
	children,
	initGameStateFromProps,
}) => {
	const [state, dispatch] = React.useReducer(
		gameStateReducer,
		initGameStateFromProps || initialGameState
	);

	return (
		<GameStateContext.Provider value={state}>
			<GameDispatchContext.Provider value={dispatch}>
				{children}
			</GameDispatchContext.Provider>
		</GameStateContext.Provider>
	);
};

export default GameStateProvider;
