const WsServiceEnvVar = 'REACT_APP_CROSSLETT_WS_URL';
const serviceUrl = process.env[WsServiceEnvVar];
import { ActionType } from '@no-q-webapp/websocket/src/service/actionTypes';
import { getUserInfo } from '../user';
import {
	EventBodies,
	ClientMessageBodies,
	ClientBodyDataUnion,
} from '@no-q-webapp/websocket/src/service/eventTypes';
import { createCustomAction } from 'typesafe-actions';
import { ResolveType } from 'typesafe-actions/dist/type-helpers';
import { create } from 'domain';

if (!serviceUrl) {
	throw Error(`${WsServiceEnvVar} not defined.`);
}

let crosslettWS: WebSocket | null = null;
export const getWebSocket = () => {
	if (
		!crosslettWS ||
		[WebSocket.CLOSING, WebSocket.CLOSED].some(
			state => state === crosslettWS?.readyState
		)
	) {
		crosslettWS = new WebSocket(serviceUrl);
	}
	return crosslettWS;
};

/**
 * Get an open websocket as a promise. Useful for async able code.
 * @returns
 */
export const getWebSocketPromise = async () => {
	return new Promise<WebSocket>((resolve, reject) => {
		const ws = getWebSocket();
		if (ws.readyState !== WebSocket.OPEN) {
			// Websocket is not open, wait and resolve the promise when the
			// websocket is open
			ws.onopen = () => {
				resolve(ws);
			};
			ws.onerror = () => {
				reject();
			};
		}
		// There's an open websocket in cache, so resolve this.
		resolve(ws);
	});
};

const getSessionAndUserId = () => {
	const uI = getUserInfo();
	return {
		sessionId: uI.sessionId,
		// userId: uI.userId,
	};
};

/**
 * Helper function to create action creators with some typing in place.
 * @param type
 * @param createHandler
 * @returns
 */
const createCrosslettWSMessage = <
	TType extends (typeof ActionType)[keyof typeof ActionType],
	TArgs extends any[] = [],
	TReturn extends {} = {}
>(
	type: TType,
	createHandler: (...args: TArgs) => TReturn
) => {
	return (...args: TArgs) => {
		const message = {
			action: type,
			data: createHandler(...args),
		};
		return message;
	};
};

export const CrosslettWSMessageCreators = {
	joinLobby: createCrosslettWSMessage(
		ActionType.JoinLobby,
		(lobbyInviteId: string, screenName: string) => ({
			...getSessionAndUserId(),
			lobbyInviteId,
			screenName,
		})
	),
	requestNewGame: createCrosslettWSMessage(
		ActionType.NewGameRequested,
		(lobbyId: string) => ({
			...getSessionAndUserId(),
			lobbyId,
		})
	),
};
