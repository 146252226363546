import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { CrosslettAPI } from '../../service/API/crosslettApi';
import { lobbyActions } from '../../state/Lobby/lobbyActions';
import {
	useLobbyDispatch,
	useLobbyState,
} from '../../state/Lobby/lobbyContext';
import Button from '../Button/Button';
import { LobbyInteractions } from './components/LobbyInteractions';
import { useLobbyIdOnMount } from './hooks/useLobbyIdOnMount';
import { useLobbySocket } from '../../state/Lobby/lobbyWebSocketContext';
import { CrosslettWSMessageCreators } from '../../service/API/crosslettWs';

interface IJoinLobbyProps {}

const JoinLobby: React.FunctionComponent<IJoinLobbyProps> = props => {
	const lobbyState = useLobbyState();
	const lobbyDispatch = useLobbyDispatch();
	const { sendMessage } = useLobbySocket();
	const nav = useNavigate();

	useLobbyIdOnMount(lobbyDispatch);

	const joinLobby = async () => {
		const lobbyId = lobbyState.lobbyId;
		const screenName = lobbyState.screenName;

		if (!lobbyId || !screenName) {
			toast.error(`Lobby ID and screenName required`);
			return;
		}

		sendMessage(CrosslettWSMessageCreators.joinLobby(lobbyId, screenName));
		
		nav(`../game?lobbyId=${lobbyId}`);
	};

	return (
		<div>
			<Helmet>
				<title>Crosslett - Join Lobby</title>
			</Helmet>
			<LobbyInteractions>
				<input
					style={{ flexBasis: '100%' }}
					value={lobbyState.lobbyId}
					onChange={e =>
						lobbyDispatch(lobbyActions.updateLobbyId(e.currentTarget.value))
					}
				/>
				<input
					placeholder={'Your name'}
					style={{ flexBasis: '100%' }}
					onChange={e =>
						lobbyDispatch(lobbyActions.updateScreenName(e.currentTarget.value))
					}
					value={lobbyState.screenName}
				/>
				<Button onClick={joinLobby}>Enter</Button>
				<Button onClick={() => nav(-1)}>Back</Button>
			</LobbyInteractions>
		</div>
	);
};

export default JoinLobby;
