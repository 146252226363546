export interface DiceItem {
	diceId: string;
	letter: string;
}

export interface LobbyResponse {
	lobbyState: string;
	letterset: { letters: string; _id: string; dice: DiceItem[] };
	winner: string;
	latestGameStartTime: string;
	latestGameWinTime: string;
	participants: any[];
	updatedAt: string;
}

export type ParsedLobbyResponse = Omit<
	LobbyResponse,
	'latestGameStartTime' | 'latestGameWinTime' | 'updatedAt'
> & {
	latestGameStartTime: Date;
	latestGameWinTime: Date;
	updatedAt: Date;
};

export const parseLobbyResponse = (l: LobbyResponse): ParsedLobbyResponse => {
	return {
		...l,
		latestGameStartTime: new Date(l.latestGameStartTime),
		latestGameWinTime: new Date(l.latestGameWinTime),
		updatedAt: new Date(l.updatedAt),
	};
};
