import * as React from 'react';
import { toast } from 'react-toastify';
import { CrosslettAPI } from '../../service/API/crosslettApi';
import { validateWords } from '../../service/game';
import { lobbyActions } from '../../state/Lobby/lobbyActions';
import {
	LobbyDispatchContext,
	useLobbyDispatch,
	useLobbyState,
} from '../../state/Lobby/lobbyContext';
import Button from '../Button/Button';
import Game from '../Game/Game';
import { NavWrapper } from '../Nav/NavWrapper';
import { ButtonStyleNavLink } from '../Nav/StyledNavLink';
import { useLobbyIdOnMount } from './hooks/useLobbyIdOnMount';
import { useWebSocket } from '../../hooks/useWebSocket';
import { CrosslettWSMessageCreators } from '../../service/API/crosslettWs';
import { ActionType } from '@no-q-webapp/websocket/src/service/actionTypes';
import { parseLobbyResponse } from '../../service/API/lobby/parseLobbyResponse';

interface ILobbyProps {}

const Lobby: React.FunctionComponent<ILobbyProps> = props => {
	const lobbyState = useLobbyState();
	const lobbyDispatch = useLobbyDispatch();
	useLobbyIdOnMount(lobbyDispatch);
	const {lastMessage, readyState, sendMessage} = useWebSocket();

	React.useEffect(() => {
		switch (lastMessage?.action) {
			case ActionType.GameStateUpdated:
				lobbyDispatch(lobbyActions.receiveLobbyState(
					parseLobbyResponse(lastMessage.data)));
				break;
		}
	}, [lastMessage, lobbyDispatch]);

	React.useEffect(() => {
		if (!lobbyState.lobbyId) {
			return;
		}
		sendMessage(CrosslettWSMessageCreators.joinLobby(lobbyState.lobbyId, lobbyState.screenName));
	}, [lobbyState.lobbyId]);

	const shareLobbyUrl = React.useCallback(() => {
		const clipboardAvailable = 'clipboard' in navigator;
		if (!clipboardAvailable) {
			toast.error('No access to clipboard');
			return;
		}
		const url = window.location.href.replace('game', 'join');
		navigator.clipboard.writeText(url);
		toast.success(`Lobby invite link copied to clipboard`);
	}, [lobbyState.lobbyId]);

	const startGame = async () => {
		if (!lobbyState.lobbyId) {
			return;
		}
		if (sendMessage) {
			sendMessage(CrosslettWSMessageCreators.requestNewGame(lobbyState.lobbyId));
		}		
	};

	return (
		<>
			<NavWrapper style={{ textAlign: 'center' }}>
				<ButtonStyleNavLink to="../menu">Leave</ButtonStyleNavLink>
				<div>
					<h2>Players</h2>
					<span>
						{lobbyState.participants?.map(p => p.screenName).join(',')}
					</span>
				</div>
				<Button onClick={shareLobbyUrl}>Share Lobby</Button>
				<Button onClick={startGame}>Start</Button>
			</NavWrapper>
			{lobbyState.letterset?.letters && <Game validate={validateWords} />}
		</>
	);
};

export default Lobby;
