import * as React from 'react';
import styled from 'styled-components';
import { BaseDieStyling } from '../Dice/Die';

interface IButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
	children: React.ReactNode;
}

export const Button = styled.button`
	${BaseDieStyling}

	font-size: 1.3rem;
	font-weight: bold;

	text-transform: uppercase;
	word-wrap: pre;

	padding: 0.5rem;

	border-width: 0;
`;

export default Button;
