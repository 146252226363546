import { getType } from 'typesafe-actions';
import { saveGameState } from '../../service/save';
import withLogging from '../../util/actionLogging';
import { lobbyActions, LobbyActions } from './lobbyActions';
import { GameActions, gameActions } from '../Game/gameActions';
import { LobbyState, setStoredScreenName } from './lobbyState';
import { gameStateReducer } from '../Game/gameReducer';

export const withLocalSaveState = (
	reducer: React.Reducer<LobbyState, LobbyActions>
) => {
	return (state: LobbyState, action: LobbyActions) => {
		const returnState = reducer(state, action);
		// saveGameState(returnState);
		return returnState;
	};
};

const ActionsHandledByGameReducer = Object.values(gameActions)
	.map(a => getType(a))
	.reduce<Set<string>>((set, a) => set.add(a), new Set());

/**
 * TypeGuard for game state actions given we have an action union on our hands.
 * @param action
 * @returns
 */
const isGameStateAction = (action: { type: string }): action is GameActions =>
	ActionsHandledByGameReducer.has(action.type);

export const lobbyStateReducer = withLogging(
	withLocalSaveState((state: LobbyState, action: LobbyActions): LobbyState => {
		// Run all game state actions through the game state reducer on this
		// nested field.
		if (isGameStateAction(action)) {
			return {
				...state,
				game: gameStateReducer(state.game, action),
			};
		} else {
			switch (action.type) {
				case getType(lobbyActions.updateScreenName): {
					setStoredScreenName(action.payload.name);
					return { ...state, screenName: action.payload.name };
				}
				case getType(lobbyActions.updateLobbyId): {
					return { ...state, lobbyId: action.payload.lobbyId };
				}
				case getType(lobbyActions.receiveLobbyState): {
					const game =
						action.payload.letterset &&
						action.payload.letterset._id !== state.letterset?._id
							? gameStateReducer(
									state.game,
									gameActions.receiveDice(action.payload.letterset.dice)
							  )
							: state.game;
					return { ...state, ...action.payload, game };
				}
				default:
					return state;
			}
		}
	})
);
