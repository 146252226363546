import React, { createContext, useContext } from 'react';
import { GameState } from './gameState';

export const GameStateContext = createContext<GameState | undefined>(undefined);
export const GameDispatchContext = createContext<
	React.Dispatch<any> | undefined
>(undefined);

export const useGameState = () => {
	const gs = useContext(GameStateContext);
	if (!gs) {
		throw Error('No Game State?');
	}
	return gs;
};

export const useGameDispatch = () => {
	const gs = useContext(GameDispatchContext);
	if (!gs) {
		throw Error('No Game Dispatch?');
	}
	return gs;
};
