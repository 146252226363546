export interface DieState {
	id: string;
	letter: string;
	placed: boolean;
	incorrect: boolean;
	correct: boolean;
	x: number | undefined;
	y: number | undefined;
	trayIndex: number;
}

/**
 * All the letters in the game
 */
export type GameDice = {
	[diceId: string]: DieState;
};

export interface GameState {
	dice: GameDice;
	words: {
		[word: string]: {
			word: string;
			valid: boolean;
		};
	};
	isCorrect: boolean;
}

export const initialGameState: GameState = {
	dice: {},
	words: {},
	isCorrect: false,
};
